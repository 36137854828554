import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import StartPage from '../subpages/StartPage'

const { pages } = require('../content/content')

const IndexPage = () => {
  return (
    <Layout>
      <SEO titleAll={pages.start.title} description={pages.start.description} />
      <StartPage />
    </Layout>
  )
}

export default IndexPage
